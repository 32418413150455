<template>
  <Context>
    <McHeader />
  </Context>
</template>

<script>
import Context from "@/blocks/global/Context.vue";
import AppContent from "@/AppContent.vue";
import McHeader from "@/blocks/McHeader.vue";

export default {
  name: "App2",
  components: {
    McHeader,
    AppContent,
    Context,
  },
  // props: {
  //   fetchedMatches: Object,
  //   fetchedLeagues: Object
  // },
  // mounted() {
  //   console.log(window.json_data);
  // }
};
</script>
