<template>
  <li class="sk__filters-item">
    <input
        :id="league.entityId"
        :class="['sk__filters-checkbox', { 'sk__filters-checkbox_indeterminate': isIndeterminate }]"
        type="checkbox"
        :checked="isChecked"
        :indeterminate="isIndeterminate"
        @change="toggle"
    >
    <label class="sk__filters-label" :for="league.entityId">{{ league.name }}</label>
    <button v-if="league.competitions" :class="['sk__filters-btn', { 'sk__filters-btn_open': childrenOpen }]" @click="toggleChildren"></button>
    <ul v-if="league.competitions" :class="['sk__filters-item__list', { 'sk__filters-item__list_open': league.competitions && childrenOpen }]">
      <FilterItem
          v-for="tournament in Object.values(league.competitions)"
          :key="tournament.entityId"
          :league="tournament"
          :selected-items="selectedItems"
          @update="handleChildUpdate"
      />
    </ul>
  </li>
</template>

<script>
export default {
  name: "FilterItem",
  props: {
    league: Object,
    selectedItems: Object,
    openItem: String,
  },
  data() {
    return {
      childrenOpen: false,
      isChecked: this.isCheckedCheck(),
      isIndeterminate: this.isIndeterminateCheck(),
    };
  },
  watch: {
    selectedItems: {
      handler() {
        this.updateStates();
      },
      deep: true,
    },
    openItem(newVal) {
      this.childrenOpen = newVal === this.league.entityId;
    }
  },
  methods: {
    toggle(e) {
      const checked = e.target.checked;

      if (this.league.competitions) {
        Object.values(this.league.competitions).forEach(child => {
          this.$emit('update', { id: child.entityId, checked, type: 'tournament' });
        });
      }

      this.update(checked, checked && !this.isIndeterminateCheck());
    },
    update(checked, isOnlyLeague = false) {
      if (isOnlyLeague) {
        this.$emit('update', { id: this.league.entityId, checked, type: 'onlyLeagues' });
      } else {
        this.$emit('update', { id: this.league.entityId, checked, type: 'league' });
      }
    },
    handleChildUpdate({ id, checked }) {
      this.$emit('update', { id, checked, type: 'tournament' });

      const childrenChecked = Object.values(this.league.competitions).filter(child => this.selectedItems.tournament.includes(child.entityId));
      const isIndeterminate = childrenChecked.length > 0 && childrenChecked.length < Object.values(this.league.competitions).length;
      const isAllSelected = childrenChecked.length === Object.values(this.league.competitions).length;

      if (isIndeterminate) {
        this.update(true, false);
      } else if (isAllSelected) {
        this.update(true, true);
      } else {
        this.update(false, false);
      }
    },
    toggleChildren() {
      this.$emit('toggle-item', this.league.entityId);
    },
    updateStates() {
      this.isChecked = this.isCheckedCheck();
      this.isIndeterminate = this.isIndeterminateCheck();
    },
    isCheckedCheck() {
      if (this.league.competitions) {
        return Object.values(this.league.competitions).every(child => this.selectedItems.tournament.includes(child.entityId));
      }
      return this.selectedItems.tournament.includes(this.league.entityId) || this.selectedItems.league.includes(this.league.entityId);
    },
    isIndeterminateCheck() {
      if (this.league.competitions) {
        const childrenChecked = Object.values(this.league.competitions).filter(child => this.selectedItems.tournament.includes(child.entityId));
        return childrenChecked.length > 0 && childrenChecked.length < Object.values(this.league.competitions).length;
      }
      return false;
    }
  },
  mounted() {
    this.childrenOpen = this.openItem === this.league.entityId;
  }
};
</script>