<template>
  <div class="sk-match__status sk-match__status_football">
    <div class="sk-match__status-img"></div>
    <div v-if="nextTab" class="sk-match__status-info">
      <span class="sk-match__status-title">There are no matches playing {{playDay}}</span>
      <p class="sk-match__status-description">Please, check out the
        <span class="sk-match__status-link" @click="handleOtherTab">{{nextTab}}</span>
        events</p>
    </div>
    <div v-else class="sk-match__status-info">
      <span class="sk-match__status-title">There are no matches fitting the filters</span>
      <p class="sk-match__status-description">Please, {{isMcHeader ? '' : 'pick another date in calendar or'}} change the filter settings</p>
    </div>
  </div>
</template>

<script>
import {injectContext} from "@/mixins/injectContext";

export default {
  name: "EventStatus",
  mixins: [injectContext],
  props: {
    nextTab: String,
    isCalendar: Boolean,
    isMcHeader: Boolean,
  },
  computed: {
    playDay() {
      return this.isCalendar ? 'on this date' : this.context.activeTab.toLowerCase();
    }
  },
  methods: {
    handleOtherTab() {
      this.updateContext('activeDate', null);
      this.updateContext('calendarMatches', null);
      this.updateContext('activeTab', this.nextTab);
    }
  }
};
</script>