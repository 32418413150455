<template>
  <div class="sk__tabs sk-scrollbar">
    <Tab v-for="[key, value] in Object.entries(tabs)" :status="key" :group="value" :key="value.title" />
  </div>
</template>

<script>
import Tab from "@/components/tabs/Tab.vue";

export default {
  name: "Tabs",
  components: {Tab},
  props: {
    tabs: Object,
  },
};
</script>