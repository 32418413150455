<template>
  <Context>
    <AppContent />
  </Context>
</template>

<script>
import Context from "@/blocks/global/Context.vue";
import AppContent from "@/AppContent.vue";

export default {
  name: "App",
  components: {
    AppContent,
    Context,
  },
};
</script>

