<template>
  <div class="sk-mc__events-item">
    <MatchesStatus :status="status" :amount="amount" />
    <div class="sk-mc__strings">
      <MatchString v-for="match in Object.values(group.events || group)"
          :key="match.id"
          :status="match.status"
          :date="match.date"
          :description="match.description"
          :home="match.home"
          :away="match.away"
          :winner="match.winner"
          :elapsed="match.elapsed"
          :league-id="match.leagueId"
          :url="match.urlSegment"
          :period="match.period"
      />
    </div>
  </div>
</template>

<script>
import MatchesStatus from "@/components/matches/MatchesStatus.vue";
import MatchString from "@/components/matches/MatchString.vue";

export default {
  name: "EventItem",
  components: {MatchString, MatchesStatus},
  props: {
    group: Object,
    status: String,
  },
  computed: {
    amount() {
      return this.group.events ? this.group.events.length : Object.values(this.group).length;
    }
  }
};
</script>
