<template>
  <div class="sk-datepicker" @click="toggleCalendar">
    <span v-if="!appliedDate" class="sk-datepicker__icon"></span>
    <span v-else class="sk-datepicker__value">{{ formattedDate }}</span>
    <div v-if="isCalendarVisible" class="sk-datepicker__calendar">
      <div class="sk-datepicker__calendar-header">
        <button class="prev-month" @click.stop="changeMonth(-1)" :disabled="!isPrevMonthAvailable">
          <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
            <path d="M1 9L5 5L1 1" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        <span>{{ monthName }} {{ currentYear }}</span>
        <button class="next-month" @click.stop="changeMonth(1)" :disabled="!isNextMonthAvailable">
          <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
            <path d="M1 9L5 5L1 1" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
      <table>
        <thead>
        <tr>
          <th>Mo</th>
          <th>Tu</th>
          <th>We</th>
          <th>Th</th>
          <th>Fr</th>
          <th>Sa</th>
          <th>Su</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(week, weekIndex) in calendar" :key="weekIndex">
          <td
              v-for="(day, dayIndex) in week"
              :key="dayIndex"
              :class="{
                selected: isSelected(day),
                disabled: isDisabled(day),
                today: isToday(day),
              }"
              @click.stop="selectDate(day)"
          >
            {{ day ? day.getDate() : '' }}
          </td>
        </tr>
        </tbody>
      </table>
      <div class="sk-datepicker__calendar-buttons">
        <button class="reset-date" @click.stop="resetDate" :disabled="!appliedDate">Reset</button>
        <button class="apply-date" @click.stop="applyDate" :disabled="!selectedDate || context.isLoading">
          <span>Apply</span>
          <span v-if="context.isLoading" class="sk-spinner"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {injectContext} from "@/mixins/injectContext";
import {fetchData} from "@/helpers/requests";

export default {
  mixins: [injectContext],
  data() {
    return {
      isCalendarVisible: false,
      currentDate: new Date(),
      selectedDate: null,
      appliedDate: null,
      minDate: new Date(),
      maxDate: new Date(),
    };
  },
  watch: {
    'context.activeDate': {
      async handler(newActiveDate) {
        if (newActiveDate === null) {
          this.resetDate();
          return;
        }

        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        const newDateObj = new Date(newActiveDate);

        const formattedNewDate = this.formatDate(newDateObj);
        const formattedDates = {
          [this.formatDate(today)]: 'Today',
          [this.formatDate(yesterday)]: 'Yesterday',
          [this.formatDate(tomorrow)]: 'Tomorrow'
        };

        if (formattedDates[formattedNewDate]) {
          this.updateContext('activeTab', formattedDates[formattedNewDate]);
          this.resetDate();
        }  else {
          // const dateString = newActiveDate.toLocaleString().split(',')[0].split('.').reverse().join('-');
          // console.log(dateString)
          const timestamp = this.getTimestampWithTimezone(newActiveDate);
          this.updateContext('isLoading', true);
          const matches = await fetchData(timestamp);
          this.updateContext('calendarMatches', matches);
          this.updateContext('isLoading', false);
          this.updateContext('activeTab', '');
        }
        this.appliedDate = this.selectedDate;
        this.isCalendarVisible = false;
      }
    },
  },
  computed: {
    currentYear() {
      return this.currentDate.getFullYear();
    },
    currentMonth() {
      return this.currentDate.getMonth();
    },
    monthName() {
      return this.currentDate.toLocaleString("en", { month: "long" });
    },
    formattedDate() {
      if (!this.appliedDate) return null;
      const day = this.appliedDate.getDate().toString().padStart(2, "0");
      const month = (this.appliedDate.getMonth() + 1)
          .toString()
          .padStart(2, "0");
      return `${day}.${month}`;
    },
    calendar() {
      const startOfMonth = new Date(this.currentYear, this.currentMonth, 1);
      const startDayOfWeek = (startOfMonth.getDay() + 6) % 7;
      const daysInMonth = new Date(
          this.currentYear,
          this.currentMonth + 1,
          0
      ).getDate();

      const weeks = [];
      let week = [];
      for (let i = 0; i < startDayOfWeek; i++) {
        week.push(null);
      }

      for (let day = 1; day <= daysInMonth; day++) {
        week.push(new Date(this.currentYear, this.currentMonth, day));
        if (week.length === 7) {
          weeks.push(week);
          week = [];
        }
      }

      if (week.length) {
        weeks.push(week);
      }

      return weeks;
    },
    isPrevMonthAvailable() {
      const prevMonth = this.currentDate.getMonth() - 1;
      return prevMonth >= this.minDate.getMonth();
    },
    isNextMonthAvailable() {
      const nextMonth = this.currentDate.getMonth() + 1;
      return nextMonth <= this.maxDate.getMonth();
    },
  },
  methods: {
    toggleCalendar() {
      this.isCalendarVisible = !this.isCalendarVisible;
      this.selectedDate = this.appliedDate;
    },
    changeMonth(direction) {
      this.currentDate = new Date(
          this.currentYear,
          this.currentMonth + direction,
          1
      );
    },
    isSelected(day) {
      if (!day || !this.selectedDate) return false;
      return day.toDateString() === this.selectedDate.toDateString();
    },
    isDisabled(day) {
      if (!day) return true;
      return day < this.minDate || day > this.maxDate;
    },
    isToday(day) {
      if (!day) return false;
      const today = new Date();
      return day.toDateString() === today.toDateString();
    },
    selectDate(day) {
      if (!day || this.isDisabled(day)) return;
      this.selectedDate = day;
    },
    resetDate() {
      this.selectedDate = null;
      this.appliedDate = null;
      this.isCalendarVisible = false;
      this.updateContext('activeDate', null);
      this.updateContext('calendarMatches', null);
      this.updateContext('activeTab', this.context.activeTab || 'Today');
    },
    applyDate() {
      if (this.selectedDate === this.context.activeDate) {
        this.isCalendarVisible = false;
      } else {
        this.updateContext('activeDate', this.selectedDate);
      }
    },
    formatDate(dateString) {
      const dateObj = new Date(dateString);
      const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
      return dateObj.toLocaleDateString('en-US', options);
    },
    getTimestampWithTimezone(dateString) {
      const date = new Date(dateString);
      const timezoneOffset = date.getTimezoneOffset() * 60000;
      const timestampWithTimezone = date.getTime() - timezoneOffset;

      return timestampWithTimezone / 1000;
    }
  },
  mounted() {
    this.minDate.setDate(this.currentDate.getDate() - 15);
    this.maxDate.setDate(this.currentDate.getDate() + 15);

    document.addEventListener("click", (event) => {
      if (!this.$el.contains(event.target)) {
        this.isCalendarVisible = false;
      }
    });
  },
};
</script>
