

export const trackUrl = {
    data() {
        return {
            baseURL: null,
            league: null,
            tournament: null,
            tabsVariants: ['today', 'tomorrow', 'yesterday'],
            filters: {
                league: [],
                tournament: [],
                onlyLeagues: []
            }
        }
    },

    watch: {
        'context.activeFilters': {
            handler(newValue) {
                const { league, tournament, onlyLeagues } = newValue;

                if (league.length && league.length === 1) {
                    this.league = Object.values(this.leaguesData).find((item) => item.entityId === league[0]).urlSegments[0];
                } else if (onlyLeagues.length && onlyLeagues.length === 1) {
                    this.league =  Object.values(this.leaguesData).find((item) => item.entityId === onlyLeagues[0]).urlSegments[0];
                } else {
                    this.league = null;
                }

                if (tournament.length && tournament.length === 1) {
                    const activeLeague =  Object.values(this.leaguesData).find((item) => item.entityId === league[0]);
                    this.tournament = Object.values(activeLeague.competitions).find((item) => item.entityId === tournament[0]).urlSegments.slice(-1);
                } else {
                    this.tournament = null;
                }

                this.changeUrl();
            },
            deep: true,
        },

        'context.activeTab': {
            handler() {
                this.changeUrl();
            },
        }
    },

    methods: {
        url() {
            const league = this.league ? `/${this.league}` : '';
            const tournament = this.tournament ? `/${this.tournament}` : '';
            const tab = this.context.activeTab && this.context.activeTab !== 'Now' ? `/${this.context.activeTab.toLowerCase()}` : '';
            return this.baseUrl + league + tournament + tab + '/';
        },

        changeUrl() {
            const newUrl = this.url();
            window.history.replaceState({}, document.title, newUrl);
        },

        checkUrl() {
            const currentUrl = window.location.href;
            const array = currentUrl.replace(this.baseUrl, '').split('/').filter(Boolean);
            const [part1, part2, part3] = array;

            let league = null;
            let tournament = null;
            let activeTab = null;

            switch(array.length) {
                case 3:
                    [league, tournament, activeTab] = array;
                    break;
                case 2:
                    league = part1;
                    this.tabsVariants.includes(part2) ? activeTab = part2 : tournament = part2;
                    break;
                case 1:
                    league = part1;
                    this.tabsVariants.includes(part1) ? activeTab = part1 : league = part1;
                    break;
            }

            activeTab && this.updateContext('activeTab', activeTab.charAt(0).toUpperCase() + activeTab.slice(1));

            if (league) {
                const activeLeague = Object.values(this.leaguesData).find(item => item.urlSegments.includes(league));
                if (activeLeague) {
                    const leagueId = activeLeague.entityId;

                    if (tournament) {
                        const activeTournament = Object.values(activeLeague.competitions).find(item => item.urlSegments.includes(tournament));
                        if (activeTournament) {
                            this.filters.tournament.push(activeTournament.entityId);
                        }
                        this.filters.league.push(leagueId);
                    } else {
                        this.filters.onlyLeagues.push(leagueId);
                        Object.values(activeLeague.competitions).forEach(item => this.filters.tournament.push(item.entityId));
                    }

                    this.updateContext('activeFilters', this.filters);
                }
            }
        },
    },
}
