<template>
  <article class="sk-mc__card">
    <div class="sk-mc__card-head">
      <p class="sk-mc__card-head__title">{{league}}</p>
      <span v-if="status === 'Live'" class="sk-mc__card-head__label sk-mc__card-head__label_live">Live</span>
      <span v-else-if="status === 'Finished'" class="sk-mc__card-head__label sk-mc__card-head__label_finished">FT</span>
      <time v-else-if="status === 'Upcoming'" class="sk-mc__card-head__label sk-mc__card-head__label_upcoming" :datetime="date">{{ formattedTime }}</time>
    </div>
    <div class="sk-mc__card-content">
      <div :class="['sk-mc__card-team', homeTeamClass]">
        <div class="sk-mc__card-team__info">
          <img class="sk-mc__card-team__logo" :src="home.logoLink" alt="team logo">
          <p class="sk-mc__card-team__name">{{home.abbr || home.name}}</p>
        </div>
        <span class="sk-mc__card-team__score">{{getTeamScore(home)}}</span>
      </div>
      <div :class="['sk-mc__card-team', awayTeamClass]">
        <div class="sk-mc__card-team__info">
          <img class="sk-mc__card-team__logo" :src="away.logoLink" alt="team logo">
          <p class="sk-mc__card-team__name">{{away.abbr || away.name}}</p>
        </div>
        <span class="sk-mc__card-team__score">{{getTeamScore(away)}}</span>
      </div>
    </div>
    <a class="sk-mc__card-link" :href="matchUrl" aria-label="Read more"></a>
  </article>
</template>

<script>
import {injectContext} from "@/mixins/injectContext";

export default {
  name: "MatchCard",
  props: {
    status: String,
    date: String,
    // description: String,
    home: Object,
    away: Object,
    winner: String,
    url: String,
    periodShort: String,
    // elapsed: String,
    leagueId: Number,
    league: String,
    // slug: String,
  },
  mixins: [injectContext],
  data() {
    return {
      catalogData: window.json_data,
    };
  },
  computed: {
    formattedTime() {
      const matchDate = new Date(this.date);
      const options = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

      return matchDate.toLocaleTimeString("en-US", options);
    },
    homeTeamClass() {
      return this.getTeamClass(this.winner === "home");
    },
    awayTeamClass() {
      return this.getTeamClass(this.winner === "away");
    },
    // leagueUrl() {
    //   return this.catalogData.events.categories.find((league) => league.entityId === this.leagueId).urlSegments[0];
    // },
    matchUrl() {
      return `${this.catalogData?.urls.mc}${this.url}/`;
    }
  },
  methods: {
    getTeamClass(isWinner) {
      if (!this.winner) {
        return {};
      }
      return {
        'sk-mc__card-team_winner': isWinner,
        'sk-mc__card-team_loser': !isWinner
      };
    },
    getTeamScore(team) {
      if (this.status === "Upcoming" && team.score === '0') {
        return "-";
      } else {
        return team.score;
      }
    }
  },
  // mounted() {
  //   console.log(this.status, this.home)
  // }
};
</script>
