const protocol = window.location.protocol;
const host = window.location.host;
const apiUrl = `${protocol}//${host}/wp-json/match-center/v1`;

export const fetchData = async (date) => {
    try {
        const response = await fetch(`${apiUrl}/events`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                date,
            })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}
