<template>
  <div class="sk__filters">
    <button class="sk__filters-value" @click="toggleList">
      <span class="sk__filters-value__content">All leagues</span>
      <span :class="['sk__filters-value__ico', { 'sk__filters-value__ico_active': hasActiveFilters }]"></span>
    </button>
    <div v-if="isListOpen" class="sk__filters-list">
      <button class="sk__filters-list__clear" @click="handleClear" :disabled="!hasActiveFilters">Clear All</button>
      <ul class="sk__filters-list__content sk-scrollbar">
        <FilterItem
            v-for="league in Object.values(leagues)"
            :key="league.entityId"
            :league="league"
            :selected-items="selectedItems"
            :open-item="openItem"
            @update="handleUpdate"
            @toggle-item="handleToggleItem"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import FilterItem from "@/components/filters/FilterItem.vue";
import {injectContext} from "@/mixins/injectContext";

export default {
  name: "Filters",
  components: { FilterItem },
  props: {
    leagues: Array,
  },
  mixins: [injectContext],
  data() {
    return {
      isListOpen: false,
      selectedItems: {
        ...this.context.activeFilters,
        onlyLeagues: this.context.activeFilters.onlyLeagues || [],
      },
      openItem: null,
    };
  },
  computed: {
    hasActiveFilters() {
      return this.selectedItems.league.length || this.selectedItems.onlyLeagues.length;
    }
  },
  watch: {
    'context.activeFilters': {
      handler(newValue) {
        this.selectedItems = {
          ...newValue,
          onlyLeagues: newValue.onlyLeagues || [],
        };
      },
      deep: true,
    },
  },
  methods: {
    toggleList() {
      this.isListOpen = !this.isListOpen;
    },
    handleUpdate({ id, checked, type }) {
      if (type === 'onlyLeagues') {
        this.updateOnlyLeagues(id, checked);
      } else if (type === 'league') {
        this.updateLeague(id, checked);
      } else if (type === 'tournament') {
        this.updateTournament(id, checked);
      }

      this.updateContext('activeFilters', this.selectedItems);
    },
    updateOnlyLeagues(id, checked) {
      if (checked) {
        this.addItemToArray(this.selectedItems.onlyLeagues, id);
        this.removeItemFromArray(this.selectedItems.league, id);
      } else {
        this.removeItemFromArray(this.selectedItems.onlyLeagues, id);
        this.addItemToArray(this.selectedItems.league, id);
      }
    },
    updateLeague(id, checked) {
      if (checked) {
        this.addItemToArray(this.selectedItems.league, id);
        this.removeItemFromArray(this.selectedItems.onlyLeagues, id);
      } else {
        this.removeItemFromArray(this.selectedItems.league, id);
      }
    },
    updateTournament(id, checked) {
      if (checked) {
        this.addItemToArray(this.selectedItems.tournament, id);
      } else {
        this.removeItemFromArray(this.selectedItems.tournament, id);
      }

      this.updateParentLeague(id);
    },
    updateParentLeague(tournamentId) {
      const parentLeague = Object.values(this.leagues).find(league => Object.values(league.competitions).some(comp => comp.entityId === tournamentId));
      if (parentLeague) {
        const allTournamentsSelected =Object.values(parentLeague.competitions).every(comp => this.selectedItems.tournament.includes(comp.entityId));
        const parentInLeagues = this.selectedItems.league.includes(parentLeague.entityId);

        if (allTournamentsSelected) {
          this.addItemToArray(this.selectedItems.onlyLeagues, parentLeague.entityId);
          this.removeItemFromArray(this.selectedItems.league, parentLeague.entityId);
        } else {
          this.removeItemFromArray(this.selectedItems.onlyLeagues, parentLeague.entityId);
          if (!parentInLeagues) {
            this.addItemToArray(this.selectedItems.league, parentLeague.entityId);
          }
        }
      }
    },
    addItemToArray(array, id) {
      if (!array.includes(id)) {
        array.push(id);
      }
    },
    removeItemFromArray(array, id) {
      const index = array.indexOf(id);
      if (index !== -1) {
        array.splice(index, 1);
      }
    },
    handleToggleItem(id) {
      if (this.openItem === id) {
        this.openItem = null;
      } else {
        this.openItem = id;
      }
    },
    handleClear() {
      this.selectedItems = { league: [], tournament: [], onlyLeagues: [] };
      this.updateContext('activeFilters', this.selectedItems);
    }
  },
  mounted() {
    Object.values(this.leagues).length &&  Object.values(this.leagues).forEach(league => {
      if (this.selectedItems.league.includes(league.entityId)) {
        if (league.children) {
          league.children.forEach(child => {
            if (!this.selectedItems.tournament.includes(child.entityId)) {
              this.selectedItems.tournament.push(child.entityId);
            }
          });
        }
      }
    });

    document.addEventListener("click", (event) => {
      if (!this.$el.contains(event.target)) {
        this.isListOpen = false;
      }
    });
  }
};
</script>