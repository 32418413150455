<template>
  <div :class="['sk__tabs-item', tabClass]" @click="handleClick">
    <span>{{group.title}}</span>
    <span v-if="eventAmount" class="sk__tabs-item__amount">{{eventAmount}}</span>
  </div>
</template>

<script>
import {injectContext} from "@/mixins/injectContext";

export default {
  name: "Tab",
  props: {
    status: String,
    group: Object,
  },
  mixins: [injectContext],
  computed: {
    tabClass() {
      return `sk__tabs-item_${this.status.toLowerCase()}
      ${!this.eventAmount ? 'sk__tabs-item_disabled' : ''}
      ${this.context.activeTab === this.group.title ? 'sk__tabs-item_active' : ''}`
    },
    eventAmount() {
      return Object.values(this.group.eventGroups).reduce((acc, curr) => acc + curr.length, 0);
    },
    // filteredGroup() {
    //   const {league, tournament} = this.context.activeFilters;
    //
    //   if (!league.length && !tournament.length) {
    //     return this.group;
    //   }
    //
    //   const filteredEventGroups = {};
    //
    //   for (const [groupKey, groupEvents] of Object.entries(this.group.eventGroups || {})) {
    //     filteredEventGroups[groupKey] = groupEvents.filter(event => {
    //       return (
    //           league.includes(event.leagueId) &&
    //           tournament.includes(event.competitionId)
    //       );
    //     });
    //   }
    //   return {
    //     ...this.group,
    //     eventGroups: filteredEventGroups
    //   };
    // }
  },
  methods: {
    handleClick() {
      if (this.eventAmount) {
        this.updateContext('activeDate', null);
        this.updateContext('calendarMatches', null);
        this.updateContext('activeTab', this.group.title);
      }
    }
  },
};
</script>