<template>
  <h2 class="sk-title sk-title_lined">
    <time :datetime="dateString">{{ formattedDate }}</time>
  </h2>
</template>

<script>
export default {
  name: "MatchesDate",
  props: {
    dateString: String,
  },
  computed: {
    formattedDate() {
      const date = new Date(this.dateString);
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const month = months[date.getMonth()];
      const dayOfMonth = date.getDate();
      const dayOfWeek = daysOfWeek[date.getDay()];

      return `${month} ${dayOfMonth}, ${dayOfWeek}`;
    },
  },
};
</script>
