<template>
  <div class="sk-mc__header">
    <h2 class="sk-mc__header-title">Football Match Center</h2>
    <a class="sk-mc__header-link" :href="urls.mc">Match Center</a>
    <nav class="sk__tabs">
      <a class="sk__tabs-item" :href="urls.yesterday"><span>Yesterday</span></a>
      <a class="sk__tabs-item sk__tabs-item_active" :href="urls.today"><span>Today</span></a>
      <a class="sk__tabs-item" :href="urls.tomorrow"><span>Tomorrow</span></a>
    </nav>
    <Filters :leagues="leagues"/>
  </div>
  <div v-if="hasMatches" class="sk-mc__cards sk-scrollbar">
      <MatchCard v-for="match in context.filteredMatches || matches"
                 :key="match.id"
                 :status="match.status"
                 :date="match.date"
                 :home="match.home"
                 :away="match.away"
                 :winner="match.winner"
                 :league-id="match.leagueId"
                 :league="match.league"
                 :url="match.urlSegment"
                 :period-short="match.periodShort"
      />
  </div>
  <EventStatus v-else :is-calendar="false" :is-mc-header="true"/>

</template>

<script>
import Tab from "@/components/tabs/Tab.vue";
import MatchCard from "@/components/mc-header/MatchCard.vue";
import MatchString from "@/components/matches/MatchString.vue";
import Filters from "@/blocks/Filters.vue";
import {injectContext} from "@/mixins/injectContext";
import EventStatus from "@/blocks/matches/EventStatus.vue";

export default {
  name: "McHeader",
  components: {EventStatus, Filters, MatchString, MatchCard, Tab},
  mixins: [injectContext],
  // props: {
  //   matches: Object,
  //   leagues: Object
  // },
  data() {
    return {
      catalogData: window.json_data,
    };
  },
  watch: {
    'context.activeFilters': {
      handler() {
        this.filterMatches();
      },
      deep: true,
    }
  },
  computed: {
    matches() {
      return this.catalogData.events;
    },
    urls() {
      return this.catalogData.urls;
    },
    leagues() {
      return this.catalogData.categories;
    },
    hasMatches() {
      const {league, tournament, onlyLeagues} = this.context.activeFilters;

      if (!league.length && !tournament.length && !onlyLeagues.length) {
        return this.matches.length;
      }

      return this.context.filteredMatches?.length
    },
  },
  methods: {
    filterMatches() {
      const {league, tournament, onlyLeagues} = this.context.activeFilters;

      if (!league.length && !tournament.length && !onlyLeagues.length) {
        this.updateContext('filteredMatches', null);
        return;
      }

      const filteredEvents = this.matches.filter(match => {
        return (
            league.includes(match.leagueId) &&
            tournament.includes(match.competitionId) ||
            onlyLeagues.includes(match.leagueId)
        );
      });

      this.updateContext('filteredMatches', filteredEvents);
    },
  },
};
</script>
