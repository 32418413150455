<template>
  <Context>
    <AppContent />
  </Context>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import Filters from "@/blocks/Filters.vue";
import Tabs from "@/blocks/Tabs.vue";
import EventBlock from "@/blocks/matches/EventBlock.vue";
import Context from "@/blocks/global/Context.vue";
import AppContent from "@/AppContent.vue";

export default {
  name: "App",
  components: {
    AppContent,
    Context,
  },
};
</script>

