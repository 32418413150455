<template>
    <section class="sk-mc__head">
      <h1 class="sk-title">Matches</h1>
      <div class="sk-mc__head-panel">
        <Filters :leagues="leaguesData" />
        <Tabs :tabs="tabsData" />
        <DatePicker />
      </div>
    </section>
    <EventBlock :catalog-data="catalogData"/>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import Filters from "@/blocks/Filters.vue";
import Tabs from "@/blocks/Tabs.vue";
import EventBlock from "@/blocks/matches/EventBlock.vue";
import {injectContext} from "@/mixins/injectContext";
import {trackUrl} from "@/mixins/trackUrl";

export default {
  name: "AppContent",
  components: {
    EventBlock,
    Tabs,
    Filters,
    DatePicker,
  },
  mixins: [injectContext, trackUrl],
  data() {
    return {
      catalogData: window.json_data,
    };
  },
  watch: {
    'context.activeFilters': {
      handler() {
        const { league, tournament, onlyLeagues } = this.context.activeFilters;

        if (!league.length && !tournament.length  && !onlyLeagues.length) {
         this.updateContext('filteredMatches', this.context.catalogData);
          this.catalogData = this.context.catalogData;
        } else {
          this.filterMatches();
          this.catalogData = this.context.filteredMatches;
        }
      },
      deep: true,
    }
  },
  computed: {
    leaguesData() {
      return this.catalogData ? this.catalogData.events.categories : [];
    },
    tabsData() {
      return this.catalogData ? this.catalogData.events.groups : {};
    },
  },
  methods: {
    filterMatches() {
      const { league, tournament, onlyLeagues } = this.context.activeFilters;
      const data = this.context.catalogData;

      if (!league.length && !tournament.length && !onlyLeagues.length) {
        this.updateContext('filteredMatches', data);
        return;
      }

      const filteredGroups = {};

      for (const [groupKey, group] of Object.entries(data.events.groups || {})) {
        const filteredEventGroups = {};

        for (const [eventGroupKey, eventGroup] of Object.entries(group.eventGroups || {})) {
          const filteredEvents = eventGroup.filter(event => {
            return (
                league.includes(event.leagueId) &&
                tournament.includes(event.competitionId) ||
                onlyLeagues?.includes(event.leagueId)
            );
          });

          if (filteredEvents.length > 0) {
            filteredEventGroups[eventGroupKey] = filteredEvents;
          }
        }

        filteredGroups[groupKey] = {
          ...group,
          eventGroups: filteredEventGroups
        };
      }

      this.updateContext('filteredMatches', {
        ...data,
        events: {
          ...data.events,
          groups: filteredGroups
        }
      });
    }
  },
  mounted() {
    this.updateContext('catalogData', this.catalogData);
    this.updateContext('baseUrl', this.context.catalogData.events.baseUrl);
    this.baseUrl = this.context.baseUrl;
    // console.log(this.catalogData, 'mount app');
    this.checkUrl();
  },
};
</script>

