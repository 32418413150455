<template>
  <section class="sk-mc__events">
    <MatchesDate :date-string="matches.date || matches.data.date"/>
    <EventStatus v-if="isEventGroupsEmpty" :next-tab="nextTabWithMatches" :is-calendar="!!context.calendarMatches"/>
    <div v-else class="sk-mc__events-list">
      <template v-if="filteredMatches.eventGroups">
        <EventItem v-for="[key, value] in Object.entries(filteredMatches.eventGroups)" :status="key" :group="value" :key="value.title"/>
      </template>
      <template v-else>
        <EventItem :status="filteredMatches.data.period" :group="filteredMatches.data.events" />
      </template>
    </div>
  </section>
</template>

<script>
import MatchesDate from "@/components/matches/MatchesDate.vue";
import MatchesStatus from "@/components/matches/MatchesStatus.vue";
import MatchString from "@/components/matches/MatchString.vue";
import EventItem from "@/blocks/matches/EventItem.vue";
import EventStatus from "@/blocks/matches/EventStatus.vue";
import {injectContext} from "@/mixins/injectContext";
export default {
  name: "EventBlock",
  components: {EventStatus, EventItem, MatchString, MatchesStatus, MatchesDate},
  mixins: [injectContext],
  props: {
    catalogData: Object,
  },
  // data() {
  //   return {
  //     catalogData: null,
  //   };
  // },
  // watch: {
  //   'context.filteredMatches': {
  //     handler() {
  //      this.catalogData = this.context.filteredMatches;
  //     },
  //     deep: true,
  //   }
  // },
  computed: {
    matches() {
      if (this.context.calendarMatches) {
        return this.context.calendarMatches;
      } else {
        const activeTab = this.context.activeTab || 'Now';
        if (!this.catalogData) {
          return {};
        }

        const group = Object.values(this.catalogData.events.groups).find(group => group.title === activeTab);
        if (!group) {
          return {};
        }

        const filteredEventGroups = Object.entries(group.eventGroups || {}).filter(([key, value]) => Object.values(value).length > 0);

        return {
          ...group,
          eventGroups: Object.fromEntries(filteredEventGroups)
        };
      }
    },

    filteredMatches() {
      const {league, tournament, onlyLeagues} = this.context.activeFilters;

      if (!league.length && !tournament.length && !onlyLeagues.length) {
        return this.matches;
      }

      if (this.context.calendarMatches) {
        const filteredEvents =  this.matches.data.events.events.filter(match => {
          return (
              league.includes(match.leagueId) &&
              tournament.includes(match.competitionId) ||
              onlyLeagues?.includes(match.leagueId)
          );
        });

        return {
          ...this.matches,
          data: {
            ...this.matches.data,
            events: {
              ...this.matches.events,
              events: filteredEvents
            }
          }
        }
      } else {
        return this.matches;
      }
    },

    isEventGroupsEmpty() {
      if (this.context.calendarMatches) {
        return !this.filteredMatches.data.events.events.length;
      } else {
        const eventGroups = this.filteredMatches.eventGroups || {};
        return Object.values(eventGroups).every(group => group.length === 0);
      }
    },

    nextTabWithMatches() {
      const tabs = Object.values(this.catalogData.events.groups);

      let currentIndex = tabs.findIndex(group => group.title === this.context.activeTab);
      const totalTabs = tabs.length;

      if (currentIndex === -1) {
        for (let i = 0; i < totalTabs; i++) {
          const group = tabs[i];
          if (Object.values(group.eventGroups || {}).some(events => events.length > 0)) {
            return group.title;
          }
        }
      }

      for (let i = currentIndex + 1; i < totalTabs; i++) {
        const group = tabs[i];
        if (Object.values(group.eventGroups || {}).some(events => events.length > 0)) {
          return group.title;
        }
      }

      for (let i = currentIndex - 1; i >= 0; i--) {
        const group = tabs[i];
        if (Object.values(group.eventGroups || {}).some(events => events.length > 0)) {
          return group.title;
        }
      }

      return null;
    }
  },
};
</script>