<script>

export const ContextStateSymbol = Symbol('User settings state provider identifier');
export const ContextUpdateSymbol = Symbol('User settings update provider identifier');

export default {
  name: 'Context',
  data() {
    return {
      catalogData: null,
      activeTab: 'Now',
      activeDate: null,
      activeFilters: {
        onlyLeagues: [],
        league: [],
        tournament: []
      },
      calendarMatches: null,
      isLoading: false,
      filteredMatches: null,
      baseUrl: null,
    };
  },
  provide() {
    return {
      [ContextStateSymbol]: this.$data,
      [ContextUpdateSymbol]: this.update,
    };
  },
  methods: {
    update(property, value) {
      this[property] = value;
    },
  },
  // watch: {
  //   activeFilters: {
  //     handler(newFilters) {
  //       console.log(newFilters, 'filters');
  //     },
  //     deep: true
  //   }
  // },
  render() {
    return this.$slots.default();
  },
};
</script>