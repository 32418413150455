<template>
  <div :class="['sk-mc__string', matchClass]">
    <div class="sk-mc__string-info">
      <time class="sk-mc__string-info__time" :datetime="date">{{ this.status === 'Live' && this.elapsed ? this.elapsed + '\'' : formattedTime }}</time>
      <span class="sk-mc__string-info__status">{{ this.period }}</span>
    </div>
    <div class="sk-mc__string-teams">
      <div :class="['sk-mc__string-team', homeTeamClass]">
        <span class="sk-mc__string-team__name">{{ home.name }}</span>
        <div class="sk-mc__string-team__logo">
          <img
            class="sk-mc__string-team__logo-img"
            :src="home.logoLink"
            alt="Team logo"
          />
        </div>
        <span class="sk-mc__string-team__score">{{getTeamScore(home)}}</span>
      </div>
      <div :class="['sk-mc__string-team', awayTeamClass]">
        <span class="sk-mc__string-team__score">{{getTeamScore(away)}}</span>
        <div class="sk-mc__string-team__logo">
          <img
            class="sk-mc__string-team__logo-img"
            :src="away.logoLink"
            alt="Team logo"
          />
        </div>
        <span class="sk-mc__string-team__name">{{ away.name }}</span>
      </div>
    </div>
    <span class="sk-mc__string-ico"></span>
    <a class="sk-mc__string-link" :href="matchUrl"></a>
  </div>
</template>

<script>
import {injectContext} from "@/mixins/injectContext";

export default {
  name: "MatchString",
  props: {
    status: String,
    date: String,
    description: String,
    home: Object,
    away: Object,
    winner: String,
    url: String,
    elapsed: String,
    leagueId: Number,
    period: String,
  },
  mixins: [injectContext],
  data() {
    return {
      catalogData: window.json_data,
    };
  },
  computed: {
    matchClass() {
      if (this.status === "Finished") {
        return "sk-mc__string_finished";
      }

      if (this.status === "Live") {
        return "sk-mc__string_live";
      }

      return "";
    },

    formattedTime() {
      const matchDate = new Date(this.date);
      const options = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

      return matchDate.toLocaleTimeString("en-US", options);
    },
    homeTeamClass() {
      return this.getTeamClass(this.winner === "home");
    },
    awayTeamClass() {
      return this.getTeamClass(this.winner === "away");
    },
    matchUrl() {
      return `${this.context.baseUrl}/${this.url}/`;
    }
  },
  methods: {
    getTeamClass(isWinner) {
      if (!this.winner) {
        return {};
      }
      return {
        'sk-mc__string-team_winner': isWinner,
        'sk-mc__string-team_loser': !isWinner
      };
    },
    getTeamScore(team) {
      if (this.status === "Upcoming" && team.score === '0') {
        return "-";
      } else {
        return team.score;
      }
    }
  }
};
</script>
