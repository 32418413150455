<template>
  <div class="sk-mc__events-label">
    <span class="sk-mc__events-label__status">{{ status }}</span>
    <span class="sk-mc__events-label__amount">{{ amount }}</span>
  </div>
</template>

<script>
export default {
  name: "MatchesStatus",
  props: {
    status: String,
    amount: Number,
  },
};
</script>
